var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crew-member-table"},[_c('v-container',{staticClass:"container-xl"},[_c('base-crew-data-table',{attrs:{"title":"Crew Members","headers":_vm.headers,"search":_vm.search,"items":_vm.crewMembers,"loading":_vm.loading,"item-class":_vm.updateNote,"noCreate":_vm.agencyAdminRole},on:{"create-item":_vm.onCreateCrewMember},scopedSlots:_vm._u([{key:"item.passportExpiry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.passportExpiry))+" ")]}},{key:"item.employmentEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.employmentEndDate))+" ")]}},{key:"item.lastNote",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.lastNote))+" ")]}},{key:"item.dateOfBirth",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateAge")(item.dateOfBirth))+" ")]}},{key:"item.linkedUser",fn:function(ref){
var item = ref.item;
return [( item.linkedUser &&  item.linkedUser.isAccessToApp)?_c('div',[_vm._v(" Yes ")]):_c('div',[_vm._v(" No ")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("yesNo")(item.active))+" ")]}},{key:"item.currentVesselName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currentVesselName ? item.currentVesselName : "N/A")+" ")]}},{key:"item.currentVesselRss",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currentVesselRss ? item.currentVesselRss : "N/A")+" ")]}},{key:"item.doNotUse",fn:function(ref){
var item = ref.item;
return [(item.doNotUse == false)?_c('div',[(!_vm.agencyAdminRole)?_c('v-btn',{staticClass:"doNotUse",attrs:{"icon":""},on:{"click":function($event){(item.doNotUse = true), _vm.updateDoNotUse(item)}}},[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-checkbox-blank-circle")])],1):_vm._e()],1):_c('div',[(!_vm.agencyAdminRole)?_c('v-btn',{staticClass:"doNotUse",attrs:{"icon":""},on:{"click":function($event){(item.doNotUse = false), _vm.updateDoNotUse(item)}}},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-checkbox-blank-circle")])],1):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('MenuWrapper',[_c('CrewMemberMenuItems',{attrs:{"item":item},on:{"edit":function () { return _vm.onUpdateCrewMember(item); },"delete":function () { return _vm.onDeleteCrewMember(item); },"update-item-locally":function () { return _vm.updateCrewMemberLocally(item); }}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }